import { combineReducers } from "redux";
import changeReducer from "./changeReducer";
import usuarioReducer from "./usuarioReducer";
import sociosReducer from "./sociosReducer";
import clientes from "./clientesReducer";
import componentSocioReducer from "./componentSocioReducer";
import planAhorroReducer from "./planAhorroReducer";
import sociosEstadoReducer from "./sociosEstadoReducer";
import registerReducer from "./registroReducer";
import uepapayReducer from "./uepapayReducer";
import completeProfilesReducer from "./completeProfilesReducer";
import SettingReducer from "./SettingReducer";
import TransCardReducer from "./TransCardReducer";
import CardNetWallet from "./CardNetWallet";

export default combineReducers({
  usuario: usuarioReducer,
  socios: sociosReducer,
  changes: changeReducer,
  clientes: clientes,
  socios_component: componentSocioReducer,
  planAhorro: planAhorroReducer,
  estadoCuenta: sociosEstadoReducer,
  solicitud_registro: registerReducer,
  uepapay: uepapayReducer,
  completeProfiles : completeProfilesReducer,
  appSetting : SettingReducer,
  TransCard : TransCardReducer,
  Wallet : CardNetWallet
});
