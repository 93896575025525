// Login
export const COMENZAR_INICIO_SESION = "COMENZAR_INICIO_SESION";
export const INICIO_SESION_EXITO = "INICIO_SESION_EXITO";
export const INICIO_SESION_ERROR = "INICIO_SESION_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";
export const AUTENTIFICACION404 = "AUTENTIFICACION404";

//Login - Validacion
export const VERIFICACION_USUARIO_INICIA = "VERIFICACION_USUARIO_INICIA";
export const VERIFICACION_USUARIO_EXITO = "VERIFICACION_USUARIO_EXITO";
export const VERIFICACION_USUARIO_ERROR = "VERIFICACION_USUARIO_ERROR";

//Consulta de Transacciones.
export const CONSULTA_SOCIO_INICIO = "CONSULTA_SOCIO_INICIO";
export const CONSULTA_SOCIO_EXITO = "CONSULTA_SOCIO_EXITO";
export const CONSULTA_SOCIO_ERROR = "CONSULTA_SOCIO_ERROR";

export const UPDATE_SOCIO_INICIO = "UPDATE_SOCIO_INICIO";
export const UPDATE_SOCIO_EXITO = "UPDATE_SOCIO_EXITO";
export const UPDATE_SOCIO_ERROR = "UPDATE_SOCIO_ERROR";

export const CREAR_CUENTA_INICIO = "CREAR_CUENTA_INICIO";
export const CREAR_CUENTA_EXITO = "CREAR_CUENTA_EXITO";
export const CREAR_CUENTA_ERROR = "CREAR_CUENTA_ERROR";
export const CREAR_CUENTA_LIMPIAR = "CREAR_CUENTA_LIMPIAR";

export const CONSULTA_LISTSOCIO_INICIO = "CONSULTA_LISTSOCIO_INICIO";
export const CONSULTA_LISTSOCIO_EXITO = "CONSULTA_LISTSOCIO_EXITO";
export const CONSULTA_LISTSOCIO_ERROR = "CONSULTA_LISTSOCIO_ERROR";

export const LIST_CUENTA_SOCIO = "LIST_CUENTA_SOCIO";
export const LIST_CUENTA_SOCIO_OK = "LIST_CUENTA_SOCIO_OK";
export const LIST_CUENTA_SOCIO_ERROR = "LIST_CUENTA_SOCIO_ERROR";

//Clientes
export const CLIENTE_CONSULTA_INICIO = "CLIENTE_CONSULTA_INICIO";
export const CLIENTE_CONSULTA_EXITOSA = "CLIENTE_CONSULTA_EXITOSA";
export const CLIENTE_CONSULTA_ERROR = "CLIENTE_CONSULTA_ERROR";

//Actualizacion
export const CLIENTE_UPDATE_INICIO = "CLIENTE_UPDATE_INICIO";
export const CLIENTE_UPDATE_EXITOSA = "CLIENTE_UPDATE_EXITOSA";
export const CLIENTE_UPDATE_ERROR = "CLIENTE_UPDATE_ERROR";

//Cargar Component de Socios
export const SOCIOS_COMPONENT_INICIA = "SOCIOS_COMPONENT_INICIA";
export const SOCIOS_COMPONENT_EXITOSA = "SOCIOS_COMPONENT_EXITOSA";
export const SOCIOS_COMPONENT_ERROR = "SOCIOS_COMPONENT_ERROR";

//Plan de Cuenas
export const CUENTA_PLAN_GET = "CUENTA_PLAN_GET";
export const CUENTA_PLAN_GET_OK = "CUENTA_PLAN_GET_OK";
export const CUENTA_PLAN_GET_ERROR = "CUENTA_PLAN_GET_ERROR";

export const CUENTA_PLAN_POST = "CUENTA_PLAN_POST";
export const CUENTA_PLAN_POST_OK = "CUENTA_PLAN_POST_OK";
export const CUENTA_PLAN_POST_ERROR = "CUENTA_PLAN_POST_ERROR";

//Estado de Cuenta Asociado
export const ESTADO_CUENTA_GET = "ESTADO_CUENTA_GET";
export const ESTADO_CUENTA_GET_OK = "ESTADO_CUENTA_GET_OK";
export const ESTADO_CUENTA_GET_ERROR = "ESTADO_CUENTA_GET_ERROR";

export const ESTADO_CUENTA_POST = "ESTADO_CUENTA_POST";
export const ESTADO_CUENTA_POST_OK = "ESTADO_CUENTA_POST_OK";
export const ESTADO_CUENTA_POST_ERROR = "ESTADO_CUENTA_POST_ERROR";

//Registro de Nuevo Cliente
export const REGISTER_DOCUMENTO = "REGISTER_DOCUMENTO";
export const REGISTER_DOCUMENTO_OK = "REGISTER_DOCUMENTO_OK";
export const REGISTER_DOCUMENTO_ERROR = "REGISTER_DOCUMENTO_ERROR";
//Creacion de perfil
export const CREATE_PROFILE = "CREATE_PROFILE";
export const CREATE_PROFILE_OK = "CREATE_PROFILE_OK";
export const CREATE_PROFILE_ERROR = "CREATE_PROFILE_ERROR";


//Creacion de perfil
export const UEPAPAY = "UEPAPAY";
export const UEPAPAY_OK = "UEPAPAY_OK";
export const UEPAPAY_ERROR = "UEPAPAY_ERROR";

export const UEPAPAYGET = "UEPAPAYGET";
export const UEPAPAYGET_OK = "UEPAPAYGET_OK";
export const UEPAPAYGET_ERROR = "UEPAPAYGET_ERROR";


export const SUSCRIPCION = "SUSCRIPCION";
export const SUSCRIPCION_OK = "SUSCRIPCION_OK";
export const SUSCRIPCION_ERROR = "SUSCRIPCION_ERROR";

//TransCuenta
//Creacion de perfil
export const TRANSCUENTA = "TRANSCUENTA";
export const TRANSCUENTA_OK = "TRANSCUENTA_OK";
export const TRANSCUENTA_ERROR = "TRANSCUENTA_ERROR";


//Creacion de perfil
export const COMPROFILE = "COMPROFILE";
export const COMPROFILE_OK = "COMPROFILE_OK";
export const COMPROFILE_ERROR = "COMPROFILE_ERROR";