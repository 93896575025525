
export const Nacionalidades = [
    { "code": "DOM", "name": "República Dominicana" },
    { "code": "AFG", "name": "Afganistán" },
    { "code": "ALB", "name": "Albania" },
    { "code": "DEU", "name": "Alemania" },
    { "code": "AND", "name": "Andorra" },
    { "code": "AGO", "name": "Angola" },
    { "code": "AIA", "name": "Anguila" },
    { "code": "ATA", "name": "Antártida" },
    { "code": "ATG", "name": "Antigua y Barbuda" },
    { "code": "SAU", "name": "Arabia Saudita" },
    { "code": "DZA", "name": "Argelia" },
    { "code": "ARG", "name": "Argentina" },
    { "code": "ARM", "name": "Armenia" },
    { "code": "ABW", "name": "Aruba" },
    { "code": "AUS", "name": "Australia" },
    { "code": "AUT", "name": "Austria" },
    { "code": "AZE", "name": "Azerbaiyán" },
    { "code": "BHS", "name": "Bahamas" },
    { "code": "BGD", "name": "Bangladés" },
    { "code": "BRB", "name": "Barbados" },
    { "code": "BHR", "name": "Baréin" },
    { "code": "BEL", "name": "Bélgica" },
    { "code": "BLZ", "name": "Belice" },
    { "code": "BEN", "name": "Benín" },
    { "code": "BMU", "name": "Bermudas" },
    { "code": "BLR", "name": "Bielorrusia" },
    { "code": "MMR", "name": "Myanmar" },
    { "code": "BOL", "name": "Bolivia" },
    { "code": "BIH", "name": "Bosnia y Herzegovina" },
    { "code": "BWA", "name": "Botsuana" },
    { "code": "BRA", "name": "Brasil" },
    { "code": "BRN", "name": "Brunéi Darussalam" },
    { "code": "BGR", "name": "Bulgaria" },
    { "code": "BFA", "name": "Burkina Faso" },
    { "code": "BDI", "name": "Burundi" },
    { "code": "BTN", "name": "Bután" },
    { "code": "CPV", "name": "Cabo Verde" },
    { "code": "KHM", "name": "Camboya" },
    { "code": "CMR", "name": "Camerún" },
    { "code": "CAN", "name": "Canadá" },
    { "code": "QAT", "name": "Catar" },
    { "code": "BES", "name": "Bonaire" },
    { "code": "TCD", "name": "Chad" },
    { "code": "CHL", "name": "Chile" },
    { "code": "CHN", "name": "China" },
    { "code": "CYP", "name": "Chipre" },
    { "code": "COL", "name": "Colombia" },
    { "code": "COM", "name": "Comoras" },
    { "code": "KOR", "name": "Corea" },
    { "code": "CIV", "name": "Costa de Marfil" },
    { "code": "CRI", "name": "Costa Rica" },
    { "code": "HRV", "name": "Croacia" },
    { "code": "CUB", "name": "Cuba" },
    { "code": "CUW", "name": "Curaçao" },
    { "code": "DNK", "name": "Dinamarca" },
    { "code": "DMA", "name": "Dominica" },
    { "code": "ECU", "name": "Ecuador" },
    { "code": "EGY", "name": "Egipto" },
    { "code": "SLV", "name": "El Salvador" },
    { "code": "ARE", "name": "Emiratos Árabes Unidos" },
    { "code": "ERI", "name": "Eritrea" },
    { "code": "SVK", "name": "Eslovaquia" },
    { "code": "SVN", "name": "Eslovenia" },
    { "code": "ESP", "name": "España" },
    { "code": "USA", "name": "Estados Unidos" },
    { "code": "EST", "name": "Estonia" },
    { "code": "ETH", "name": "Etiopía" },
    { "code": "PHL", "name": "Filipinas" },
    { "code": "FIN", "name": "Finlandia" },
    { "code": "FJI", "name": "Fiyi" },
    { "code": "FRA", "name": "Francia" },
    { "code": "GAB", "name": "Gabón" },
    { "code": "GMB", "name": "Gambia" },
    { "code": "GEO", "name": "Georgia" },
    { "code": "GHA", "name": "Ghana" },
    { "code": "GIB", "name": "Gibraltar" },
    { "code": "GRD", "name": "Granada" },
    { "code": "GRC", "name": "Grecia" },
    { "code": "GRL", "name": "Groenlandia" },
    { "code": "GLP", "name": "Guadalupe" },
    { "code": "GUM", "name": "Guam" },
    { "code": "GTM", "name": "Guatemala" },
    { "code": "GUF", "name": "Guayana Francesa" },
    { "code": "GGY", "name": "Guernsey" },
    { "code": "GIN", "name": "Guinea" },
    { "code": "GNB", "name": "Guinea-Bisáu" },
    { "code": "GNQ", "name": "Guinea Ecuatorial" },
    { "code": "GUY", "name": "Guyana" },
    { "code": "HTI", "name": "Haití" },
    { "code": "HND", "name": "Honduras" },
    { "code": "HKG", "name": "Hong Kong" },
    { "code": "HUN", "name": "Hungría" },
    { "code": "IND", "name": "India" },
    { "code": "IDN", "name": "Indonesia" },
    { "code": "IRQ", "name": "Irak" },
    { "code": "IRN", "name": "Irán" },
    { "code": "IRL", "name": "Irlanda" },
    { "code": "BVT", "name": "Isla Bouvet" },
    { "code": "IMN", "name": "Isla de Man" },
    { "code": "CXR", "name": "Isla de Navidad" },
    { "code": "NFK", "name": "Isla Norfolk" },
    { "code": "ISL", "name": "Islandia" },
    { "code": "CYM", "name": "Islas Caimán" },
    { "code": "CCK", "name": "Islas Cocos" },
    { "code": "COK", "name": "Islas Cook" },
    { "code": "FRO", "name": "Islas Feroe" },
    { "code": "SGS", "name": "Georgia del sur y las islas sandwich del sur" },
    { "code": "HMD", "name": "Isla Heard e Islas McDonald" },
    { "code": "FLK", "name": "Islas Malvinas" },
    { "code": "MNP", "name": "Islas Marianas del Norte" },
    { "code": "MHL", "name": "Islas Marshall" },
    { "code": "PCN", "name": "Pitcairn" },
    { "code": "SLB", "name": "Islas Salomón" },
    { "code": "TCA", "name": "Islas Turcas y Caicos" },
    { "code": "UMI", "name": "Islas de Ultramar Menores de Estados Unidos" },
    { "code": "VIR", "name": "Islas Vírgenes" },
    { "code": "ISR", "name": "Israel" },
    { "code": "ITA", "name": "Italia" },
    { "code": "JAM", "name": "Jamaica" },
    { "code": "JPN", "name": "Japón" },
    { "code": "JEY", "name": "Jersey" },
    { "code": "JOR", "name": "Jordania" },
    { "code": "KAZ", "name": "Kazajistán" },
    { "code": "KEN", "name": "Kenia" },
    { "code": "KGZ", "name": "Kirguistán" },
    { "code": "KIR", "name": "Kiribati" },
    { "code": "KWT", "name": "Kuwait" },
    { "code": "LAO", "name": "Lao" },
    { "code": "LSO", "name": "Lesoto" },
    { "code": "LVA", "name": "Letonia" },
    { "code": "LBN", "name": "Líbano" },
    { "code": "LBR", "name": "Liberia" },
    { "code": "LBY", "name": "Libia" },
    { "code": "LIE", "name": "Liechtenstein" },
    { "code": "LTU", "name": "Lituania" },
    { "code": "LUX", "name": "Luxemburgo" },
    { "code": "MAC", "name": "Macao" },
    { "code": "MDG", "name": "Madagascar" },
    { "code": "MYS", "name": "Malasia" },
    { "code": "MWI", "name": "Malaui" },
    { "code": "MDV", "name": "Maldivas" },
    { "code": "MLI", "name": "Malí" },
    { "code": "MLT", "name": "Malta" },
    { "code": "MAR", "name": "Marruecos" },
    { "code": "MTQ", "name": "Martinica" },
    { "code": "MUS", "name": "Mauricio" },
    { "code": "MRT", "name": "Mauritania" },
    { "code": "MYT", "name": "Mayotte" },
    { "code": "MEX", "name": "México" },
    { "code": "FSM", "name": "Micronesia" },
    { "code": "MDA", "name": "Moldavia" },
    { "code": "MCO", "name": "Mónaco" },
    { "code": "MNG", "name": "Mongolia" },
    { "code": "MNE", "name": "Montenegro" },
    { "code": "MSR", "name": "Montserrat" },
    { "code": "MOZ", "name": "Mozambique" },
    { "code": "NAM", "name": "Namibia" },
    { "code": "NRU", "name": "Nauru" },
    { "code": "NPL", "name": "Nepal" },
    { "code": "NIC", "name": "Nicaragua" },
    { "code": "NER", "name": "Níger" },
    { "code": "NGA", "name": "Nigeria" },
    { "code": "NIU", "name": "Niue" },
    { "code": "NOR", "name": "Noruega" },
    { "code": "NCL", "name": "Nueva Caledonia" },
    { "code": "NZL", "name": "Nueva Zelanda" },
    { "code": "OMN", "name": "Omán" },
    { "code": "NLD", "name": "Países Bajos" },
    { "code": "PAK", "name": "Pakistán" },
    { "code": "PLW", "name": "Palaos" },
    { "code": "PSE", "name": "Palestina, Estado de" },
    { "code": "PAN", "name": "Panamá" },
    { "code": "PNG", "name": "Papúa Nueva Guinea" },
    { "code": "PRY", "name": "Paraguay" },
    { "code": "PER", "name": "Perú" },
    { "code": "PYF", "name": "Polinesia Francesa" },
    { "code": "POL", "name": "Polonia" },
    { "code": "PRT", "name": "Portugal" },
    { "code": "PRI", "name": "Puerto Rico" },
    { "code": "GBR", "name": "Reino Unido" },
    { "code": "CAF", "name": "República Centroafricana" },
    { "code": "CZE", "name": "República Checa" },
    { "code": "MKD", "name": "Macedonia" },
    { "code": "COG", "name": "Congo" },
    { "code": "REU", "name": "Reunión" },
    { "code": "RWA", "name": "Ruanda" },
    { "code": "ROU", "name": "Rumania" },
    { "code": "RUS", "name": "Rusia" },
    { "code": "ESH", "name": "Sahara Occidental" },
    { "code": "WSM", "name": "Samoa" },
    { "code": "ASM", "name": "Samoa Americana" },
    { "code": "BLM", "name": "San Bartolomé" },
    { "code": "KNA", "name": "San Cristóbal y Nieves" },
    { "code": "SMR", "name": "San Marino" },
    { "code": "MAF", "name": "San Martín" },
    { "code": "SPM", "name": "San Pedro y Miquelón" },
    { "code": "VCT", "name": "San Vicente y las Granadinas" },
    { "code": "SHN", "name": "Santa Helena" },
    { "code": "LCA", "name": "Santa Lucía" },
    { "code": "STP", "name": "Santo Tomé y Príncipe" },
    { "code": "SEN", "name": "Senegal" },
    { "code": "SRB", "name": "Serbia" },
    { "code": "SYC", "name": "Seychelles" },
    { "code": "SLE", "name": "Sierra leona" },
    { "code": "SGP", "name": "Singapur" },
    { "code": "SXM", "name": "Sint Maarten" },
    { "code": "SYR", "name": "Siria" },
    { "code": "SOM", "name": "Somalia" },
    { "code": "LKA", "name": "Sri Lanka" },
    { "code": "SWZ", "name": "Suazilandia" },
    { "code": "ZAF", "name": "Sudáfrica" },
    { "code": "SDN", "name": "Sudán" },
    { "code": "SSD", "name": "Sudán del Sur" },
    { "code": "SWE", "name": "Suecia" },
    { "code": "CHE", "name": "Suiza" },
    { "code": "SUR", "name": "Surinam" },
    { "code": "SJM", "name": "Svalbard y Jan Mayen" },
    { "code": "THA", "name": "Tailandia" },
    { "code": "TWN", "name": "Taiwán" },
    { "code": "TZA", "name": "Tanzania" },
    { "code": "TJK", "name": "Tayikistán" },
    { "code": "IOT", "name": "Territorio Británico del Océano Índico" },
    { "code": "ATF", "name": "Territorios Australes Franceses" },
    { "code": "TLS", "name": "Timor-Leste" },
    { "code": "TGO", "name": "Togo" },
    { "code": "TKL", "name": "Tokelau" },
    { "code": "TON", "name": "Tonga" },
    { "code": "TTO", "name": "Trinidad y Tobago" },
    { "code": "TUN", "name": "Túnez" },
    { "code": "TKM", "name": "Turkmenistán" },
    { "code": "TUR", "name": "Turquía" },
    { "code": "TUV", "name": "Tuvalu" },
    { "code": "UKR", "name": "Ucrania" },
    { "code": "UGA", "name": "Uganda" },
    { "code": "URY", "name": "Uruguay" },
    { "code": "UZB", "name": "Uzbekistán" },
    { "code": "VUT", "name": "Vanuatu" },
    { "code": "VEN", "name": "Venezuela" },
    { "code": "VNM", "name": "Vietnam" },
    { "code": "WLF", "name": "Wallis y Futuna" },
    { "code": "YEM", "name": "Yemen" },
    { "code": "DJI", "name": "Yibuti" },
    { "code": "ZMB", "name": "Zambia" },
    { "code": "ZWE", "name": "Zimbabue" }
]
