import React, { Component, useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./css/customer.css";

import tokenAuth from "./config/token";
import domainAuth from "./config/domain";

import Referidor from "./views/pages/clubReferidor/clubReferidor";
import Felicitaciones from "./views/pages/confirmacion/felicitacionesSuscripcion";
import Profiles from "./views/pages/profile/Profiles";
import Dbconfig from "./views/dbconfig";
import Rescues from "./views/pages/login/Rescues";
import Rescueprofile from "./views/pages/login/Rescueprofile";
import { Validacion } from "./Validacion/Validacion";
import Spinner, { Downloading, TokenUnistall } from "./reusable/Spinner";
import getConfig from "./config/getConfig";
import clienteAxios from "./config/axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Logout from "./views/pages/login/logout";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Registeriframe = React.lazy(() =>
  import("./views/pages/register/Register.iframe")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const WalletApi = React.lazy(() => import("./views/WalletApi/Wallet"));

const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}
const domain = localStorage.getItem("domain");
if (domain) {
  domainAuth(domain);
}

const LoadingBlue = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};
const Loading = () => {
  const [load, setLoad] = useState(false);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    getConfig().then((e) => {
      clienteAxios.defaults.headers.common["Authorization"] = e.apiKey;
      clienteAxios.defaults.headers.common["apiuser"] = e.apiuser;
      setLoad(true)
    }).catch(()=>{
      setLoadError(true)
    })
  });

  if(loadError){
    return <TokenUnistall value="Error..." />;

  }
  if (!load) {
    return <Downloading value="Iniciando..." />;
  }

 
  return (
    <HashRouter>
      <Validacion />
      <React.Suspense fallback={LoadingBlue}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/iframe/register"
            name="Register Page"
            render={(props) => <Registeriframe {...props} />}
          />
          <Route
            exact
            path="/register/:idReferidor"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/rescue"
            name="Recuperacion de Cuenta"
            render={(props) => <Rescues {...props} />}
          />
          <Route
            exact
            path="/RescueAccount/:idRescue"
            name="Recuperacion de Cuenta"
            render={(props) => <Rescueprofile {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/createProfiles/:idSolicitud"
            name="Profiles"
            render={(props) => <Profiles {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/clubReferidor/:solicitud"
            name="ClubReferidor"
            render={(props) => <Referidor {...props} />}
          />
          <Route
            exact
            path="/felicitaciones/:solicitud"
            name="Felicitaciones"
            render={(props) => <Felicitaciones {...props} />}
          />
          <Route
            exact
            path="/api/wallet/:id"
            name="Wallet"
            render={(props) => <WalletApi {...props} />}
          />
          <Route
            exact
            path="/config/parameters"
            name="configuracion"
            render={(props) => <Dbconfig {...props} />}
          />

          <Route
            path="/bienvenido"
            name="Suscriptor"
            render={(props) => <TheLayout {...props} />}
          />
          <Route
            path="/logout"
            name="Logout"
            render={(props) => <Logout {...props} />}
          />
          

          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

class App extends Component {
  render() {
    return <Loading />;
  }
}

export default App;
