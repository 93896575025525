export const coopSettings = {
  baseURL : 'https://api.coopsys.com.do/netbanking',
  //DigiCoop
  // Autorization : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDU2MjE0OTUsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjQ1NjIxNDk1LCJ1c2VyIjoiYWNhc3RpbGxvIiwiZXhwIjoxODAzMzg3ODk1LCJyb2xlIjoiQXBwIiwiQXBwIjoiTmV0QmFua2luZyIsIk5hbWVBcHAiOiJOZXRCYW5raW5nIiwidXNlck5hbWUiOiJUb2tlbkFwcCIsImZ1bGxOYW1lIjoiVG9rZW5BcHAiLCJ1c2VySUQiOjk5OSwiZmlybWF0ZSI6eyJ1c2VyTmFtZSI6ImFjYXN0aWxsbyIsImZ1bGxOYW1lIjoiU1NNICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAiLCJ1c2VySUQiOjEwMDB9LCJjbGllbnRlIjoiZGlnaWNvb3AifQ.H_YUG4pLO6Hwsq-uWWDxcVtsr0QzW6GmTm4TyMJbPbE'
  //Quisqueya
  // Autorization : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDY4NzY0OTYsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjQ2ODc2NDk2LCJ1c2VyIjoiYWNhc3RpbGxvICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICIsImV4cCI6MTgwNDY0Mjg5Niwicm9sZSI6IkFwcCIsIkFwcCI6Ik5ldEJhbmtpbmciLCJOYW1lQXBwIjoiTmV0QmFua2luZyIsInVzZXJOYW1lIjoiVG9rZW5BcHAiLCJmdWxsTmFtZSI6IlRva2VuQXBwIiwidXNlcklEIjo5OTksImZpcm1hdGUiOnsidXNlck5hbWUiOiJhY2FzdGlsbG8iLCJmdWxsTmFtZSI6IkFOR0VMIENBU1RJTExPIiwidXNlcklEIjoxMDAwfSwiY2xpZW50ZSI6ImNvb3BxdWlzcXVleWEifQ.ykX41p9TDiJzJxEKoPHGKB2zg5JqPRw_3iZhcOivuAY'
  //CoopNape
  // Autorization : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjMxNjk2NTYsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjYzMTY5NjU2LCJ1c2VyIjoiYWNhc3RpbGxvIiwiZXhwIjoxODIwOTM2MDU2LCJyb2xlIjoiQXBwIiwiQXBwIjoiTmV0QmFua2luZyIsIk5hbWVBcHAiOiJOZXRCYW5raW5nIiwidXNlck5hbWUiOiJUb2tlbkFwcCIsImZ1bGxOYW1lIjoiVG9rZW5BcHAiLCJ1c2VySUQiOjk5OSwiZmlybWF0ZSI6eyJ1c2VyTmFtZSI6ImFjYXN0aWxsbyIsImZ1bGxOYW1lIjoiQ29vcFN5cyB8IENvcmVCYW5rIiwidXNlcklEIjoxMDAwfSwiY2xpZW50ZSI6ImNvb3BuYXBlIn0.c7XJVXgqJZeqYClKcMZUyGoOsOJ2JzUax0eEYHrLn6M'
  
  //Monte de Oro
  // Autorization : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzM3MzA3NjYsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjczNzMwNzY2LCJ1c2VyIjoiYWNhc3RpbGxvIiwiZXhwIjoxODMxNDk3MTY2LCJyb2xlIjoiQXBwIiwiQXBwIjoiTmV0QmFua2luZyIsIk5hbWVBcHAiOiJOZXRCYW5raW5nIiwidXNlck5hbWUiOiJUb2tlbkFwcCIsImZ1bGxOYW1lIjoiVG9rZW5BcHAiLCJ1c2VySUQiOjk5OSwiZmlybWF0ZSI6eyJ1c2VyTmFtZSI6ImFjYXN0aWxsbyIsImZ1bGxOYW1lIjoiQ29vcFN5cyB8IENvcmVCYW5rIiwidXNlcklEIjoxMDAwfSwiY2xpZW50ZSI6ImNvb3BlcmF0aXZhbW9udGVkZW9ybyJ9.Jp9IAzgKMXs1KkK149L2BT_eYKehLlplQF9lXR_qAY0'

  // CoopPropes
  // Autorization : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzU4NjQwMTEsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjc1ODY0MDExLCJ1c2VyIjoiYWNhc3RpbGxvIiwiZXhwIjoxODMzNjMwNDExLCJyb2xlIjoiQXBwIiwiQXBwIjoiTmV0QmFua2luZyIsIk5hbWVBcHAiOiJOZXRCYW5raW5nIiwidXNlck5hbWUiOiJUb2tlbkFwcCIsImZ1bGxOYW1lIjoiVG9rZW5BcHAiLCJ1c2VySUQiOjk5OSwiZmlybWF0ZSI6eyJ1c2VyTmFtZSI6ImFjYXN0aWxsbyIsImZ1bGxOYW1lIjoiQ29vcFN5cyB8IENvcmVCYW5rIiwidXNlcklEIjoxMDAwfSwiY2xpZW50ZSI6ImNvb3Bwcm9wZXMifQ.PE5rk7Aafy9fhRc6zAnWrrpUpPINaZkmZBqHnB_GeQY'

  //CoopHospvical
  // Autorization : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2OTQ4NzE0ODYsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjk0ODcxNDg2LCJ1c2VyIjoiYWNhc3RpbGxvIiwiZXhwIjoxODUyNzI0Mjg2LCJyb2xlIjoiQXBwIiwiQXBwIjoiTmV0QmFua2luZyIsIk5hbWVBcHAiOiJOZXRCYW5raW5nIiwidXNlck5hbWUiOiJUb2tlbkFwcCIsImZ1bGxOYW1lIjoiVG9rZW5BcHAiLCJ1c2VySUQiOjk5OSwiZmlybWF0ZSI6eyJ1c2VyTmFtZSI6ImFjYXN0aWxsbyIsImZ1bGxOYW1lIjoiQ29vcFN5cyB8IENvcmVCYW5rIiwidXNlcklEIjoxMDAwfSwiY2xpZW50ZSI6ImNvb3Bob3NwdmljYWwifQ.MDpJV_5vuvtn93vfsWIoPtHcjo30-yyzxSgNvxpS5-c'

  //CoopColonial
  Autorization : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2OTUxMjg3MzgsImlzcyI6ImNvb3BzeXMuY29tLmRvIiwibmJmIjoxNjk1MTI4NzM4LCJ1c2VyIjoidXNlciIsImV4cCI6MTg1Mjk4MTUzOCwicm9sZSI6IkFwcCIsIkFwcCI6Ik5ldEJhbmtpbmciLCJOYW1lQXBwIjoiTmV0QmFua2luZyIsInVzZXJOYW1lIjoiVG9rZW5BcHAiLCJmdWxsTmFtZSI6IlRva2VuQXBwIiwidXNlcklEIjo5OTksImZpcm1hdGUiOnsidXNlck5hbWUiOiJ1c2VyIiwiZnVsbE5hbWUiOiJDb29wU3lzIHwgQ29yZUJhbmsiLCJ1c2VySUQiOjEwMDB9LCJjbGllbnRlIjoiY29vcGNvbG9uaWFsIn0.ANkkqgpvBz0NbDZzJqVYOE-dA6w7eflP1_gFTE5oTw8'
};
