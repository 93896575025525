const initialState = {
  cargando: false,
  SettingCarNet: {},
  error: false,
  ErrorData: "",
  addCard: false,
  Purchase : {
    status : '',
    response : {}
  },
  activate : {
    show : false,
    profile : {}
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "wallet->init":
      return {
        ...state,
        cargando: true,
      };
    case "wallet":
      return {
        ...state,
        cargando: false,
        SettingCarNet: action.payload,
      };
    case "wallet-error":
      return {
        ...state,
        cargando: false,
        error: false,
        ErrorData: action.payload,
      };
    case "wallet-addCard":
      return {
        ...state,
        addCard: action.show,
      };
    case "wallet-Purchase->init" : 
      return {
        ...state,
        Purchase : {
          status : 'init',
          response : {}
        }
      };
    case "wallet-Purchase" : 
      return {
        ...state,
        Purchase : {
          status : 'OK',
          response : action.payload
        }
      };
      case "wallet-Purchase->sucess" : 
      return {
        ...state,
        Purchase : {
          status : '',
          response : {}
        }
      };




      case "Wallet-Activate" : 
      return {
        ...state,
        activate : {
          show : true,
          profile : action.payload
        }
      };
    default:
      return state;
  }
}
